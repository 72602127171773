import React from 'react';
import './our-work.scss';
import { Button } from '../Button/Button';
import { Heading } from '../Heading/Heading';
import { navigate } from "gatsby"
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image";


export const OurWorkSection = ({ title, ctaText, link, image, mobileImage }) => {

    const desktopImage = getImage(image);
    const mobileImg = getImage(mobileImage);

    const images = withArtDirection(desktopImage, [
        {
            media: "(max-width: 767px)",
            image: mobileImg,
        },
    ])

    return (
        <section className="our-work" onClick={() => navigate("/our-work/")} role="presentation">
            < div className="container" >
                <div className="gr-12">
                    <Heading
                        level="h3"
                        decorationDown
                        centered
                    >
                        {title}
                    </Heading>
                </div>
                <div className="gr-12 no-gutter@md">
                    <div className="our-work__inner">
                        <GatsbyImage
                            image={images}
                            className="image"
                            alt="work inner image"
                        />
                        <div className="our-work__textbox">
                            <Button
                                link={link}
                                special
                            >
                                {ctaText}
                            </Button>
                        </div>
                    </div>
                </div>
            </div >
        </section >
    );
};
