import React, { useRef } from 'react';
import './intro-section.scss';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export const IntroSection = ({ image, title }) => {

    const parsedImage = getImage(image);
    const animateRef = useRef();


    return (
        <section className="intro-section">
            <div className="container">
                <div className="gr-12 no-gutter@md">
                    <div className="intro-section__inner"
                        ref={animateRef}
                    >
                        <div className="intro-section__box">
                            <div className="image">
                                <GatsbyImage
                                    image={parsedImage}
                                    className="rspimg holder-img"
                                    alt={title}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};