import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';


export const FadeDown = ({ children, fold }) => {
  const fadeDownRef = useRef();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const scrollTrigger = ScrollTrigger.create({
      trigger: fadeDownRef.current,
      start: "20% bottom",
      toggleClass: 'is-animated',
      once: true,
    });

    return () => {
      scrollTrigger.kill();
    }
  }, [])

  return (
    <div
      ref={fadeDownRef}
      className={classNames(
        'fade-down',
        {
          'fade-down--fold': fold,
        }
      )}>
      {children}
    </div>
  );
};

export default FadeDown;
