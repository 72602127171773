import React from 'react';
import './circle-decoration.scss';
import classNames from 'classnames';


export const CircleDecoration = ({ variant, position }) => {

  return (
    <div className={classNames(
      "circle-decoration",
      `circle-decoration--${variant}`,
      `circle-decoration--${position}`
    )}>
    </div>
  )
};

