import React, { useEffect, useRef } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './heading.scss';
import classNames from 'classnames';


export const Heading = ({
  level,
  decorated,
  intro,
  children,
  small,
  job,
  white,
  slider,
  centered,
  centeredOnMobile,
  revealAnimation,
  decorationDown,
  headingWrap,
  service,
  xxl,
}) => {

  const Element = `${level}`;
  const headingRef = useRef();
  const gsapQuery = gsap.utils.selector(headingRef);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    let animations = [];

    animations.push(
      gsap.to(headingRef.current, {
        duration: 1,
        ease: 'none',
        scrollTrigger: {
          trigger: headingRef.current,
          start: '50% bottom',
          toggleActions: 'play none none none',
          toggleClass: 'animated',
          once: true,
        }
      })
    );

    if (headingRef.current.classList.contains('reveal-text')) {
      const revealTextBg = gsapQuery('.overlay');
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: headingRef.current,
          start: "50% bottom",
          toggleActions: "restart none none reset",
          once: true,
        }
      });
      tl.to(headingRef.current, { width: "auto", stagger: 0.2, ease: "power2.out" }); // for text
      tl.to(revealTextBg, { left: "100%", stagger: 0.2, ease: "power2.out" }, 0.5); // for the background
      animations.push(tl);
    }

    return () => {
      animations.forEach((animation) => animation.kill())
    }

  }, [gsapQuery]);

  return (
    <Element className={classNames("heading", {
      "heading--decorated": decorated,
      "heading--intro": intro,
      "heading--small": small,
      "heading--card": job,
      "heading--white": white,
      "heading--slider": slider,
      "heading--centered": centered,
      "heading--centered-on-mobile": centeredOnMobile,
      "reveal-text": revealAnimation,
      "wrap": headingWrap,
      "heading--decoration_down": decorationDown,
      "heading--xxl": xxl,
      "heading--service": service,
    })} ref={headingRef}>
      {children}
      {revealAnimation && <div className="overlay" />}
    </Element>
  );
};
