import React from 'react';
import './cta-section.scss';
import { graphql, useStaticQuery } from "gatsby";
import { Button } from '../Button/Button';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { navigate } from "gatsby";

const IMAGE_QUERY = graphql`
{
  bottomImg: file(relativePath: {eq: "footer-logo.png"}) {
    childImageSharp {
      gatsbyImageData(
        width: 1183,
        placeholder: BLURRED
      )
    }
  }
}
`

const CtaSection = ({ label, title, link, image }) => {
  const tempImage = useStaticQuery(IMAGE_QUERY);
  const finalImage = tempImage.bottomImg;
  const parsedImage = getImage(finalImage);

  return (
    <section
      className="cta-section"
    >
      <div className="container">
        <div className="gr-12">
          <div className="cta-section__holder">
            <p className="cta-section__subtitle">
              {label}
            </p>
            <h3 className="cta-section__title heading" onClick={() => navigate(link)} role="presentation">
              {title}
            </h3>
            <Button
              link={link}
              special
              noText
            >
            </Button>
          </div>
        </div>

        <GatsbyImage
          image={parsedImage}
          className="rspimg cta-section__footer-logo"
          alt={label}
        />
      </div>
    </section>
  )
};

export default CtaSection;
