import React from 'react';
import './intro-holder.scss';
import classNames from 'classnames';
import {Heading} from '../../components/Heading/Heading';
import {CircleDecoration} from '../CircleDecoration/CircleDecoration';

export const IntroHolder = ({
                                id,
                                level,
                                title,
                                small,
                                centered,
                                radialDecoration,
                                radialDecorationColor,
                                radialDecorationPosition,
                                preTitle,
                                marginBottomNone
                            }) => {
    return (
        <>
            <section className={classNames(
                'intro-holder',
                {
                    'intro-holder--small': small,
                    'intro-holder--centered': centered,
                    'intro-holder--mb0': marginBottomNone,
                })}
                     id={id}
            >
                <div className="container">
                    <div className="gr-12">
                        <div className="intro-holder__wrap">
                            {preTitle &&
                                <p className="pretitle">{preTitle}</p>
                            }
                            {!centered &&
                                <Heading
                                    level={level}
                                    animated
                                    intro
                                    decorated
                                >
                                    {title}
                                </Heading>
                            }
                            {centered &&
                                <Heading
                                    level={level}
                                    animated
                                    intro
                                    decorated
                                    centered
                                >
                                    {title}
                                </Heading>
                            }
                            {radialDecoration &&
                                <CircleDecoration
                                    variant={radialDecorationColor}
                                    position={radialDecorationPosition}
                                />
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
