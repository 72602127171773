import React, { useLayoutEffect, useRef, Fragment } from "react";
import "./text-photo-section.scss";
import classNames from "classnames";
import { Heading } from "../../components/Heading/Heading";
import { Button } from "../Button/Button";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { CircleDecoration } from "../CircleDecoration/CircleDecoration";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ArrowRight } from "react-feather";

export const TextPhotoSection = ({
  title,
  description,
  smallDescription,
  image,
  image2,
  btnLink,
  btnText,
  reverse,
  singleImage,
  multipleImages,
  imagesTouching,
  smallText,
  vertical,
  radialTextDecoration,
  radialTextDecorationColor,
  radialTextDecorationPosition,
  radialmagesDecoration,
  radialmagesDecorationColor,
  radialmagesDecorationPosition,
  smallPaddingTop,
  smallPaddingBottom,
  largePaddingBottom,
  extraSmallPaddingBottom,
  imageRight,
}) => {
  const parsedImage = getImage(image);
  const parsedImage2 = getImage(image2);
  const sectionRef = useRef();
  const gsapQuery = gsap.utils.selector(sectionRef);
  // wait until DOM has been rendered

  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    let animations = [];

    const images = gsapQuery(".img-animate");
    const content = gsapQuery(".text-photo-section__text");

    const imgTop = gsapQuery(".img-top");
    const imgBottom = gsapQuery(".img-bottom");

    if (images.length > 0) {
      animations.push(
        gsap.fromTo(
          images,
          {
            y: "-5vh",
          },
          {
            y: "5vh",
            scrollTrigger: {
              trigger: sectionRef.current,
              scrub: true,
              start: "top bottom",
            },
            ease: "none",
          }
        )
      );
    }

    if (imgTop.length > 0) {
      animations.push(
        gsap.fromTo(
          imgTop,
          {
            yPercent: -20,
            ease: "none",
          },
          {
            yPercent: 20,
            scrollTrigger: {
              trigger: sectionRef.current,
              scrub: true,
              start: "top bottom",
            },
          }
        )
      );
    }

    if (imgBottom.length > 0) {
      animations.push(
        gsap.fromTo(
          imgBottom,
          {
            yPercent: 20,
            ease: "none",
          },
          {
            yPercent: -20,
            scrollTrigger: {
              trigger: sectionRef.current,
              scrub: true,
              start: "top bottom",
            },
          }
        )
      );
    }

    if (content.length > 0) {
      animations.push(
        gsap.fromTo(
          content,
          {
            y: "5vh",
          },
          {
            y: "-5vh",
            scrollTrigger: {
              trigger: sectionRef.current,
              scrub: true,
              start: "top bottom",
            },
            ease: "none",
          }
        )
      );
    }

    return () => {
      animations.forEach((animation) => animation.kill());
    };
  }, [gsapQuery]);

  return (
    <section
      className={classNames("text-photo-section", {
        "text-photo-section--reverse": reverse,
        "text-photo-section--single_image": singleImage,
        "text-photo-section--multiple_images": multipleImages,
        "text-photo-section--small_text": smallText,
        "text-photo-section--images_touching": imagesTouching,
        "text-photo-section--vertical": vertical,
        "text-photo-section--small_description": smallDescription,
        "text-photo-section--image_right": imageRight,
      })}
      ref={sectionRef}
    >
      {!vertical && (
        <div className="container">
          <div className="gr-12 no-gutter@md">
            <div
              className={classNames("text-photo-section__inner", {
                "small-padding-top": smallPaddingTop,
                "small-padding-bottom": smallPaddingBottom,
                "extra-small-padding-bottom": extraSmallPaddingBottom,
                "large-padding-bottom": largePaddingBottom,
              })}
            >
              <div className="text-photo-section__illustration vertical">
                {!multipleImages && (
                  <div className="holder">
                    <GatsbyImage
                      image={parsedImage}
                      className="rspimg holder-img img-animate"
                      alt={description.toString()}
                    />
                    {radialmagesDecoration && (
                      <CircleDecoration
                        variant={radialmagesDecorationColor}
                        position={radialmagesDecorationPosition}
                      />
                    )}
                  </div>
                )}
                {multipleImages && (
                  <div className="holder">
                    <GatsbyImage
                      image={parsedImage}
                      className="rspimg holder-img  img-top"
                      alt={description}
                    />
                    <GatsbyImage
                      image={parsedImage2}
                      className="rspimg holder-img  img-bottom"
                      alt={description}
                    />
                    {radialmagesDecoration && (
                      <CircleDecoration
                        variant={radialmagesDecorationColor}
                        position={radialmagesDecorationPosition}
                      />
                    )}
                  </div>
                )}
              </div>
              <div className="text-photo-section__text">
                <div className="wrap">
                  {!smallText && (
                    <Heading level="h2" decorationDown animated>
                      {title}
                    </Heading>
                  )}
                  {smallText && (
                    <Heading level="h2" decorationDown animated>
                      {title}
                    </Heading>
                  )}
                  <p className="description">{description}</p>
                  {btnText && (
                    <Button
                      link={btnLink}
                      secondary
                      withIcon
                      icon={
                        <Fragment>
                          <ArrowRight size="24" />
                        </Fragment>
                      }
                    >
                      {btnText}
                    </Button>
                  )}
                  {radialTextDecoration && (
                    <CircleDecoration
                      variant={radialTextDecorationColor}
                      position={radialTextDecorationPosition}
                    ></CircleDecoration>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {vertical && (
        <>
          <div className="container">
            <div className="gr-12 no-gutter@md">
              <div className="text-photo-section__text">
                <div className="wrap">
                  <Heading level="h2" decorationDown>
                    {title}
                  </Heading>
                  <p className="description">{description}</p>
                  {btnText && (
                    <Button link={btnLink} special>
                      {btnText}
                    </Button>
                  )}
                </div>
              </div>
            </div>
            <div className="gr-12 no-gutter@md">
              <div className="text-photo-section__illustration vertical">
                <div className="holder">
                  <div className="box">
                    <div className="image vertical img-animate">
                      <GatsbyImage
                        image={parsedImage}
                        className="rspimg inner-img"
                        alt={description}
                      />
                      {radialmagesDecoration && (
                        <CircleDecoration
                          variant={radialmagesDecorationColor}
                          position={radialmagesDecorationPosition}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </section>
  );
};
